<script lang="ts" setup>
import { computed } from 'vue';
import CheckboxIcon from '@/components/Elements/CheckboxIcon.vue';
import RadioIcon from '@/components/Elements/RadioIcon.vue';
import SwitchIcon from '@/components/Elements/SwitchIcon.vue';

interface IProps {
  modelValue: boolean;
  modelName?: string;
  label: string;
  type: string;
  disabled?: boolean;
  error?: boolean;
}

const props = withDefaults(defineProps<IProps>(), {
  modelName: '',
});
const emit = defineEmits(['input-selected']);

const answer = computed(() => props.modelValue);
// eslint-disable-next-line
const toggleAnswer = (e: any) => {
  if (!props.disabled) {
    emit('input-selected');
  }
};
</script>

<template>
  <div
    class="flex items-center cursor-pointer justify-start rounded-xl p-4 text-start border border-secondary-300 w-full"
    :class="{
      'bg-primary-200': answer,
      'bg-secondary-75': !answer,
    }"
    @click="toggleAnswer">
    <CheckboxIcon
      v-if="type == 'checkbox'"
      v-model="answer"
      :model-name="modelName"
      :is-disabled="disabled"/>
    <RadioIcon
      v-if="type == 'radio'"
      v-model="answer"
      :model-name="modelName"
      :is-disabled="disabled"/>
    <SwitchIcon
      v-if="type == 'switch'"
      v-model="answer"
      :model-name="modelName"
      :is-disabled="disabled"/>
    <slot>
      <span class="label ms-3 whitespace-pre-line">{{ label }}</span>
    </slot>
  </div>
</template>
