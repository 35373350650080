<script lang="ts" setup>
import { computed } from 'vue';

export interface SwitchIconProps {
  modelName: string;
  modelValue: boolean;
  isDisabled?: boolean;
}

const props = defineProps({
  modelName: { type: String, default: null },
  modelValue: { type: Boolean, default: null },
  isDisabled: { type: Boolean, default: null },
});
const emit = defineEmits(['switch-change']);
const answer = computed(() => props.modelValue);
const handleToggle = () => {
  if (!props.isDisabled) emit('switch-change');
};
</script>

<template>
  <label :for="modelName" class="flex items-center cursor-pointer relative">
    <input
      :id="modelName"
      v-model="answer"
      type="checkbox"
      :name="modelName"
      class="hidden"
      :disabled="isDisabled"
      @click="handleToggle"/>
    <div
      class="toggle-bg border-2 bg-white border-secondary-500 h-6 w-12 rounded-full flex items-center justify-end ps-2 pe-1"></div>
  </label>
</template>

<style>
.toggle-bg:after {
  content: '';
  @apply absolute top-1 left-1 bg-secondary-500 rounded-full h-4 w-4 transition shadow-sm;
}
input:checked + .toggle-bg:after {
  transform: translateX(160%);
  @apply border-white bg-white;
}
input:checked + .toggle-bg {
  transition: background-color 0.5s ease;
  @apply bg-primary border border-primary;
}

input:disabled + .toggle-bg {
  @apply cursor-not-allowed border-secondary-300 bg-secondary-400;
}
</style>
