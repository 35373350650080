<script lang="ts" setup>
import { computed } from 'vue';

const props = defineProps({
  modelName: { type: String, default: null },
  modelValue: { type: Boolean, default: null },
  isDisabled: { type: Boolean, default: null },
});

const emit = defineEmits(['radio-selected']);
const answer = computed(() => props.modelValue);

const toggleAnswer = () => {
  if (!props.isDisabled) emit('radio-selected');
};
</script>

<template>
  <div
    class="flex items-center justify-center w-5 h-5 border-2 rounded-full"
    :class="{
      'border-primary': answer,
      'bg-white border-secondary-500': !answer,
      'border-secondary-500': isDisabled,
    }"
    @click="toggleAnswer">
    <input v-model="answer" :name="modelName" type="radio" class="hidden" />
    <span
      class="w-2 h-2 rounded-full"
      :class="{
        'bg-primary border-primary': answer,
        'bg-secondary-500 border-secondary-500': answer && isDisabled,
        'bg-white border-secondary-500': !answer,
      }"></span>
  </div>
</template>
