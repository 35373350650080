<script lang="ts" setup>
import { computed } from 'vue';
import { LucideIcon } from '@/index';
const props = defineProps({
  modelName: { type: String, default: null },
  modelValue: { type: Boolean, default: false },
  isDisabled: { type: Boolean, default: false },
});
// const emit = defineEmits(['checkbox-selected']);
const answer = computed(() => props.modelValue);

// const toggleAnswer = () => {
//   if (!props.isDisabled) emit('checkbox-selected');
// };
</script>

<template>
  <input v-model="answer" :name="modelName" type="checkbox" class="hidden" />
  <div
    class="flex items-center justify-center rounded-4 w-5 h-5 border-1"
    :class="{
      'border-primary bg-primary': answer,
      'bg-white border-sky-blue-1000': !answer,
      'bg-secondary-500 border-secondary-500': isDisabled,
    }">
    <LucideIcon name="Check" color="#fff" />
  </div>
</template>
